@import  "styles";

.kt-header-menu-wrapper .kt-header-logo,
.kt-header-mobile .kt-header-mobile__logo,
.kt-aside__brand-logo{
    span {
        font-weight: 600;
        font-size: 16px;
        vertical-align: middle;
        color: #fff;
        text-transform: uppercase;
        letter-spacing: 0.1rem;
    }
}

input[type="date"]{
    -webkit-appearance: none;
}

/** col and row adjustment **/
.row {
    margin-right: -7.5px;
    margin-left: -7.5px;
}

[class*='col-'] {
    padding-right: 7.5px;
    padding-left: 7.5px;
}

select.form-control{
    &.is-invalid{
        appearance: none;
        -webkit-appearance: none;
        -moz-appearance: none;
    }
}

.dataTable{
    .kt-userpic{
        img{
            max-width: 35px;
            height: 35px;
        }
    } 
}

textarea.form-control{
    height: 200px;
    resize: none;
}

/** Income Receipt Bill **/
.kt-invoice{
    width: 100%;
    .kt-invoice__container {
        padding: 15px 30px;
        background: rgba(247, 239, 145, 0.42);
        border-radius: 5px;
    }
    .kt-invoice__brand{
        display: flex;
        justify-content: space-around;
        flex-wrap: wrap;
        .kt-invoice__title {
            font-weight: 600;
            font-size: 1.8rem;
            margin-top: 5px;
            color: #595d6e;
            vertical-align: top;
            text-align: center;
            .kt-invoice__desc span {
                display: block;
                &:last-child {
                    font-size: 1.2rem;
                    font-weight: 500;
                    padding-top: 4px;
                }
            }
        }
        .kt-invoice__logo {
            display: flex;
            flex-direction: column;
        }
    }
    .kt-invoice__items{
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        .kt-invoice__item {
            display: flex;
            flex-direction: column;
            flex: 1;
            color: #595d6e;
            text-align: center;
            &:first-child{
                text-align: left;
            }
            .kt-invoice__bill-subtitle{
                span {
                    border: 2px solid;
                    border-radius: 4px;
                    padding: 0.3rem 0.7rem;
                    font-weight: 600;
                    font-size: 1.2rem;
                }
            }
            .bill-control {
                width: 70%;
                text-align: left;
            }
            .billNo {
                color: #c42120bf;
            }
        }
        .kt-invoice__subtitle {
            font-weight: 500;
        }
    }
    .bill-control {
        border-bottom: 1px dashed #908d72;
        width: 30%;
        display: inline-block;
        margin: 0 10px;
        height: 20px;
        font-weight: 400;
        &.bc-1 {
            width: 56%;
        }
        &.bc-3 {
            width: 16%;
        }
        &.bc-4 {
            width: 58%;
        }
    }
    .kt-invoice__bodyItem {
        color: #595d6e;
        font-weight: 500;
        .billConrol-item {
            margin: 0 0 1rem;
        }
    }
    .kt-invoice__footerItem {
        font-weight: 500;
        .receiverName {
            font-weight: 400;
        }
        .kt-invoice__items{
            .kt-invoice__item:last-child{
                text-align: right;
                .verifysign {
                    padding: 0 50px;
                }
                &.bc-7 {
                    margin: -10px 0 10px;
                }
            }
        }
    }
} 

@media(min-width:1025px){
    .kt-header{
        height: 44px;
    }
    .kt-aside--enabled{
        .kt-header{
            &.kt-header--fixed {
                left: 250px;
            } 
        }
    } 
    .kt-header--fixed{
        .kt-wrapper{
            padding-top: 44px;
        }
        &.kt-aside--minimize{
            .kt-header{
                left: 60px;   
            }
        } 
    }
    .kt-content {
        padding: 15px 0;
    }
    .kt-container {
        padding: 0 15px;
    }
    .kt-aside--minimize{
        .kt-aside{
            width: 60px;
        } 
    }
    .kt-aside--fixed{
        &.kt-aside--minimize{
            .kt-wrapper{
                padding-left: 60px;
            } 
        }
        .kt-wrapper {
            padding-left: 250px;
        } 
    }
    .kt-aside--minimizing{
        .kt-aside{
            .kt-aside-menu{
                .kt-menu__nav{
                    width: 250px;
                }
            } 
        } 
    }
    .kt-portlet{
        &.kt-portlet--height-fluid {
            height: calc(100% - 15px);
        }
    }
    
    .kt-invoice{
        max-width: 615px;
        margin: 0 auto 15px;
    } 
}

@media (max-width: 1024px){
    .kt-portlet{
        .kt-portlet__head{
            &.kt-portlet__head--lg{
                min-height: 50px;
            }
        }
    } 
}

.kt-header__topbar{
    .kt-header__topbar-item{
        &.kt-header__topbar-item--user{
            .kt-header__topbar-user{
                height: 30px;
            }
        }
        .kt-header__topbar-icon{
            height: 30px;
            width: 30px;
        } 
        &.kt-header__topbar-item--langs{
            .kt-header__topbar-icon{
                img {
                    border-radius: 2px;
                    width: 16px;
                }
            }
        }
    }
}

.kt-portlet{
    .kt-portlet__head{
        min-height: 50px;
        padding: 0 15px;
        .kt-portlet__head-label{
            .kt-portlet__head-title{
                font-size: 1.1rem;   
            }
        } 
    }
    .kt-portlet__space-x {
        padding-left: 15px !important;
        padding-right: 15px !important;
    }
} 

.kt-portlet{
    margin-bottom: 15px;
    .kt-portlet__body{
        padding: 20px;
    }
} 

.kt-footer {
    padding: 12px 0px;
}

.kt-aside-menu{
    .kt-menu__nav{
        > .kt-menu__item{
            > .kt-menu__link{
                padding: 9px 20px;
                min-height: 40px;
                .kt-menu__link-text{
                    font-size: 0.923rem;
                }
            }
            > .kt-menu__submenu{
                .kt-menu__subnav{
                    > .kt-menu__item{
                        > .kt-menu__link {
                            padding: 0 20px;
                            padding-left: 35px;
                            min-height: 38px;
                            .kt-menu__link-text{
                                font-size: 0.923rem;
                            }
                        }
                        > .kt-menu__submenu{
                            .kt-menu__subnav{
                                > .kt-menu__item{
                                    > .kt-menu__link {
                                        padding: 0 20px;
                                        padding-left: 55px;
                                        min-height: 38px;
                                        .kt-menu__link-text{
                                            font-size: 0.923rem;
                                        }
                                    }
                                } 
                            } 
                        } 
                    } 
                } 
            } 
        } 
    } 
} 

.kt-aside {
    width: 250px;
}

.kt-aside__brand{
    padding: 0 15px;
    height: 44px;
}

/** Modal Custom css **/
.modal-header, .modal-footer{
    padding: 0.8rem 1.1rem;
}

.modal-body {
    padding: 1.2rem;
}

.modal{
    .modal-content{
        .modal-header{
            .modal-title{
                font-size: 1.1rem;
            }
            .close{
                padding: 1rem;
            }
        } 
    } 
} 

/** alter message **/
.alert{
    margin: 0 0 1rem 0;
    padding: 0.7rem 1rem;
    .alert-icon{
        padding: 0 0.769rem 0 0;
        i {
            font-size: 1.846rem;
        }
    }
}

/** react select custom design **/
.css-2b097c-container{
    .css-yk16xz-control, .css-1pahdxg-control{
        background-color: #fff;
        border-color: #e2e5ec;
        box-shadow: none;
        min-height: 34px;
        background-position: center right calc(0.375em + 0.325rem);
        cursor: pointer;
        .css-1hwfws3{
            padding: 0.3rem 1rem;
            .css-1uccc91-singleValue{
                color: #595d6e;
            }
            .css-1wa3eu0-placeholder{
                color: #282a3c;
                margin: 0;
                opacity: 0.8
            }
            .css-b8ldur-Input{
                margin: 0
            }
        }
        &:hover {
            border-color: #e2e5ec;
        }
        .css-1okebmr-indicatorSeparator{
            display: none;
        }
        .css-tlfecz-indicatorContainer, .css-1gtu0rj-indicatorContainer{
            padding: 6px 8px;
        }
        .css-6q0nyr-Svg{
            width: 14px;
            color: #595d6e;
        }
        .css-1rhbuit-multiValue{
            background: rgba(88, 103, 221, 0.15);
            .css-12jo7m5{
                color: #282a3c;
            }
        }
    }
    &.form-control{
        padding: 0;
        border: 0;
        &.is-invalid{
            .css-yk16xz-control, .css-1pahdxg-control{
                border-color: #fd397a;
                background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fd397a' viewBox='-2 -2 7 7'%3e%3cpath stroke='%23fd397a' d='M0 0l3 3m0-3L0 3'/%3e%3ccircle r='.5'/%3e%3ccircle cx='3' r='.5'/%3e%3ccircle cy='3' r='.5'/%3e%3ccircle cx='3' cy='3' r='.5'/%3e%3c/svg%3E");
                background-repeat: no-repeat;
                background-position: center right calc(0.375em + 0.325rem);
                background-size: calc(0.75em + 0.65rem) calc(0.75em + 0.65rem);
                .css-tlfecz-indicatorContainer, .css-1gtu0rj-indicatorContainer{
                    opacity: 0;
                }
            }
        }
    }
    .css-26l3qy-menu{
        box-shadow: 0px 0px 50px 0px rgba(82, 63, 105, 0.15);
        padding: 10px 0;
        margin: 0;
        .css-4ljt47-MenuList{
            padding: 0;
            .css-9gakcf-option{
                background: #f7f8fa;
                color: #595d6e;
                padding: 10px 15px;
                cursor: pointer;
            }
            .css-1n7v3ny-option{
                background: #f7f8fa;
                padding: 10px 15px;
                cursor: pointer;
            }
            .css-yt9ioa-option{
                padding: 10px 15px;
                cursor: pointer;
            }
        }
    }
}

.kt-header__brand-nav {
    display: flex;
    align-items: center;
}

.kt-menu__item .kt-menu__separator {
    margin: 0.7rem 0;
    height: 0;
    display: block;
    border-bottom: 1px solid #ebedf2;
}

.form-group{
    margin-bottom: 0.769rem;
    label{
        font-size: 0.923rem;
    }
}

.form-control-label{
    font-size: 0.923rem;
}

.form-control{
    height: calc(1.4em + 1.2rem + 2px);
    padding: 0.4rem 1rem;
    font-size: 0.923rem;
}

.kt-separator{
    &.kt-separator--space-lg, 
    &.kt-separator--lg,
    &.kt-separator--space-md, 
    &.kt-separator--md{
        margin: 1.3rem 0;
    }
}

.dataTables_wrapper{
    thead[data-test="table-foot"]{
        display: none;
    }
}

.kt-pagination.kt-pagination--brand{
    .kt-pagination__links {
        li:hover{
            a .kt-font-brand {
                color: #fff !important;
            }
        } 
    } 
} 

.siteLoader {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 20px 0;
}

.form-group{
    #kt_apps_user_add_avatar, .cke{
        ~.invalid-feedback{
            display: block;
        }
    }
}

.form-group{
    &.validate{
        .cke{
            border-color: #fd397a;
        }
    }
}

.table-icon{
    text-align: center;
    svg.veda-svg-icon{
        fill: #646c9a;
    }
    i{
        color: #646c9a;
        font-size: 24px;
    }
}

.kt-avatar.kt-avatar--circle .kt-avatar__holder {
    background-size: 100%;
}

.oc-scrollbar-container{
    &.fy-kt-scroll {
        height: 147px;
    }
    &.allown-kt-scroll{
        max-height: 170px;
    }
}

.chartOfAcc-form .form-group {
    margin: 0;
}

.oc-scrollbar-container{
    &.kt-aside-scroll{
        height: calc(100vh - 70px);
        .ps__rail-x{
            display: none;
        }
    }
}

.kt-aside{
    .kt-searchbar {
        margin: 15px 9px 0;
        .input-group{
            .input-group-prepend{
                margin-right: 0;
            }
            .input-group-text, .form-control{
                background: rgba(242, 243, 247, 0.1);
            }
            .form-control{
                color: #a2a3b7;
            }
        }
    }
}

/** Dashboard Css Start **/

.kt-widget2{
    &.pendingTask__widget{
        .kt-widget2__item{
            justify-content: initial;
        }
    }
} 

/** Dashboard Css End **/

/** Accounting css Start**/

.budget-dataTable .dataTable{
    counter-reset: Serial;
    tbody tr td:first-child:before {
        counter-increment: Serial;
        content: counter(Serial);
    }
    .blankTd-dbTable{
        display: block;
        text-align: center;
    }
}

/** Accounting css End **/

/** Chart of Account css start **/

.dataTables_wrapper{
    .dataTable{
        font-size: 12px;
        th{
            padding: 0.6rem;
            border-top: none;
            border-bottom: none;
        }
        td{
            padding: 0.25rem 0.6rem;
            .btn.btn-icon.btn-sm{
                height: 1.9rem;
                width: 1.9rem;
                [class*=" la-"]{
                    font-size: 1.4rem;
                }
            }
            .kt-badge{
                font-size: 0.75rem;
                &.kt-badge--inline {
                    padding: 0.69rem 0.6rem;
                }
            }
        }
    }
} 

.dataTable{
    button.btn.btn-label-success{
        &:disabled {
            cursor: default !important;
            &:hover{
                background-color: rgba(10, 187, 135, 0.1);
                color: #0abb87;
            }
        }
    }
} 

.budgetCheckbox-dataTable .kt-checkbox {
    margin: 0 0 15px 0 !important;
    padding: 0;
}

.dataTables_wrapper {
    .dataTable{
        .treeTable-checkbox{
            .kt-checkbox{
                padding-left: 0;
                left: 3px;
                top: -4px;
                margin-bottom: 10px;
            }
        }
    }
}
 
.treeViewItemList{
    .kt-widget6 {
        .kt-widget6__head {
            border-bottom: 1px dashed #ebedf2;
            span{
                color: #595d6e;
                font-weight: 500;
            }
            .kt-widget6__item {
                > span{
                    text-align: left;
                    font-size: 12px;
                    &:first-child{
                        flex: 0.7 1;
                    }
                    &.status{
                        flex: 0.6 1;
                    }
                }
            }
        }
        .kt-widget6__body{
            .kt-widget6__item{
                padding: 0.397rem 0.5rem;
                cursor: default;
                > span{
                    text-align: left;
                    font-size: 12px;
                    .kt-badge{
                        font-size: 0.75rem;
                        &.kt-badge--inline{
                            padding: 0.69rem 0.6rem;
                        }
                    }
                    &:first-child{
                        flex: 0.7 1;
                    }
                    .kt-svg-icon{
                        margin: 0 10px 0 0;
                        width: 18px;
                        height: 18px;
                        display: inline-block;
                        cursor: pointer;
                    }
                    .kt-widget__indent {
                        display: inline-block;
                        width: 10px;
                    }
                    &.status{
                        flex: 0.51 1;
                    }
                }
                .btn.btn-icon.btn-sm{
                    height: 1.9rem;
                    width: 1.9rem;
                }
                &:hover{
                    background-color: #fdfdfd;
                }
                &.selected{
                    background-color: #f7f8fa;
                }
            } 
            .treeViewItems-kt-scroll {
                max-height: 705px;
                &.ps--active-y{
                    padding: 0 11px 0 0;
                }
            }
        } 
    }
    &.budgetAssignItemDetails{
        .kt-widget6 {
            .kt-widget6__head {
                .kt-widget6__item {
                    padding: 0 0.5rem;
                }
            }
            .kt-widget6__item {
                > span{
                    padding: 0 0.5rem;
                    &.source{
                        flex: 1.3 1;
                    }
                    &.total {
                        flex: 0.6 1;
                    }
                    &.amount {
                        flex: 0.7 1;
                    }
                    &.account{
                        flex: 1 1;
                    }
                    &.actions{
                        flex: 0.4 1;
                    }
                }
            }
        }
    }
}

/** Chart of Account css End **/

/* Perfect Scrollbar */

.ps--active-y {
    .ps__thumb-y{
        right: 0;
    }
    
    .ps__rail-y{
        &:hover,&:focus, &.ps--clicking{
            > .ps__thumb-y{
                width: 4px; 
            }
        }
    }
}

@media(min-width: 1025px){
    .treeViewItemList .kt-widget6 .kt-widget6__body .kt-widget6__item svg g [fill] {
        transition: fill 0.3s ease;
        fill: #cacad2;
    }

    .treeViewItemList .kt-widget6 .kt-widget6__body .kt-widget6__item.selected svg g [fill]{
        fill: #5d78ff;
    }
}

@media(max-width: 1024px){
    .treeViewItemList .kt-widget6 .kt-widget6__body .kt-widget6__item svg g [fill] {
        transition: fill 0.3s ease;
        fill: #a8aabb;
    }

    .treeViewItemList .kt-widget6 .kt-widget6__body .kt-widget6__item.selected svg g [fill]{
        fill: #5d78ff;
    }
}

/* Perfect Scrollbar */

.treeViewItemList{
    .alert{
        margin: 20px 0 0;
    }
}

/** Budget Assign CSS Start **/
.budgetAssign_dataTables_wrapper{
    .table{
        font-size: 12px;
        thead{
            tr {
                th{
                    vertical-align: middle;
                    padding: 0.5rem 0.75rem;
                    color: #595d6e;
                }
            }
        } 
        tbody{
            td{
                vertical-align: middle;
                padding: 0.5rem 0.75rem;
                color: #595d6e;
                &.form-control-cell{
                    padding: 0;
                    position: relative;
                    .kt-svg-icon {
                        position: absolute;
                        top: 50%;
                        right: 4px;
                        margin-top: -12px;
                        cursor: pointer;
                    }
                    .form-group{
                        margin: 0;
                        .form-control {
                            padding: 0.5rem 0.75rem;
                            height: auto;
                            border: 0;
                            font-size: 12px;
                            color: #595d6e;
                            border-radius: 0;
                            cursor: default;
                            &.css-2b097c-container{
                                padding: 0;
                            }
                        }
                    }
                    input[type="number"].form-control, .form-control {
                        padding: 0.5rem 0.75rem;
                        height: auto;
                        border: 0;
                        font-size: 12px;
                        color: #595d6e;
                        border-radius: 0;
                        cursor: default;
                        &::-webkit-inner-spin-button{
                            -webkit-appearance: none; 
                            margin: 0; 
                        }
                        &.bamParentTitle{
                            font-weight: 500;
                        }
                    }
                    .css-yk16xz-control,.css-1pahdxg-control  {
                        background: none;
                        border: none;
                        min-height: initial;
                        border-radius: 0;
                        box-shadow: none;
                        .css-1wa3eu0-placeholder{
                            display: none;
                        }
                        .css-1hb7zxy-IndicatorsContainer,.css-1wy0on6{
                            display: none;
                        }
                        .css-1rhbuit-multiValue{
                            background: rgba(88, 103, 221, 0.15);
                            .css-12jo7m5{
                                color: #282a3c;
                            }
                        }
                    }
                    .css-yk16xz-control{
                        .css-1hwfws3{
                            max-height: 26px;
                            overflow: hidden;
                            padding: 2px 30px 2px 8px;
                        }
                    }
                }
                .kt-widget__indent {
                    display: inline-block;
                    width: 10px;
                }
                .btn.btn-icon.btn-sm{
                    height: 1.9rem;
                    width: 1.9rem;
                }
                &.bamParentTitle{
                    font-weight: 500;
                }
            }
        }
    }
    .table-striped tbody tr{
        &:nth-of-type(odd){
            .form-control{
                background: #f7f8fa;
            }
        }
        .form-control:disabled{
            background: none;
        }
    }
} 
.modal-content{
    .treeViewItemList{
        .treeViewItems-kt-scroll.modalTreeViewScroll{
            max-height: 345px;
        } 
    }
}

.journalVoucher_dataTables_wrapper{
    .table-striped{
        tbody{
            tr:last-child{
                background: none;
                .form-control{
                    background: none;
                }
            }
        } 
    } 
}

/** Expenses Voucher Print**/
.expensesVoucherInvocie {
    color: #595d6e;
    .gov-logo {
        width: 120px;
        margin: 28px 0;
        img {
            max-width: 100%;
        }
    }
    .kt-invoice__head{
        margin: 0 0 20px;
        .kt-invoice__title {
            text-align: center;
            color: #595d6e;
            span{
                display: block;
            }
            .kt-invoice__descMain {
                font-weight: 500;
                font-size: 1.4rem;
                margin: 8px 0 25px;
                span:last-child {
                    font-size: 1rem;
                }
            }
            .kt-invoice__descFoot {
                font-weight: 500;
                font-size: 1.15rem;
            }
        }
        .kt-invoice__formNo {
            text-align: right;
            padding: 65px 0;
        }
    }
    .kt-invoice-detail {
        margin: 0 0 15px;
        .kt-invoice__deLeft {
            padding: 11px 0;
        }
        .kt-invoice__deLeft,.kt-invoice__deRight{
            span {
                display: block;
                margin: 0 0 3px;
                span.digit {
                    display: inline-block;
                }
            }
            .kt-invoice__dateDeatil {
                float: right;
            }
        } 
    }
    .kt-invoice__amountInWords {
        font-weight: 500;
        padding: 10px 30px;
        margin: 0 0 30px;
    }
    .kt-invoice__narrationArea {
        margin: 0 0 40px;
        .kt-inovice__narrationTitle {
            font-weight: 500;
            font-size: 1.15rem;
            text-decoration: underline;
            margin: 0 0 5px;
        }
        .kt-invoice__narrationBox {
            border: 1px solid #ebedf2;
            min-height: 70px;
            padding: 10px 17px;
        }
    }

    .kt-invoice__withdrawTitle{
        font-weight: 500;
        margin: 0 0 15px;
        font-size: 1.1rem;
    }
    .kt-invoice__count {
        text-align: center;
    }
    .kt-invoice__role {
        margin: 30px 0 0;
        .kt-invocie__roleBy{
            span{
                display: block;
                margin: 0 0 15px;
                span.digit{
                    display: inline-block;
                    margin: 0 0 0;
                }
            }
        } 
    }
}

/** Vouchers Print **/
.kt-voucher__body{
    .NastLogo{
        width: 100px;
        margin: 10px 0;
        img {
            max-width: 100%;
        }
    }
    .kt-voucher__title{
        text-align: center;
        color: #595d6e;
        .kt-voucher__desc{
            font-weight: 500;
            font-size: 1.4rem;
            margin: 8px 0 25px;
            span:last-child {
                font-size: 1.1rem;
                font-weight: normal;
                padding: 5px 0 0;
            }
        }
        span {
            display: block;
        }
        .kt-voucher__descFoot {
            font-weight: 500;
            font-size: 1.15rem;
        }
    } 
    .kt-voucher__formNo {
        text-align: right;
        padding: 50px 0;
    }
    .kt-voucher__head {
        margin: 0 0 20px;
    }
    .kt-voucher__detail {
        margin: 0 0 15px;
        .kt-invoice__dateDeatil {
            float: right;
            span{
                display: block;
                margin: 0 0 3px;
                span.digit {
                    display: inline-block;
                }
            }
        }
    }
    .table{
        margin: 0;
    }
    .voucher__enterDetail, .voucher__detailBy, .voucher__receiverDetail{
        border: 1px solid #ebedf2;
        padding: 15px 15px 7px;
        color: #595d6e;
        span {
            display: block;
            margin: 0 0 10px;
            span.billCtrl-label {
                display: inline-block;
                width: 70px;
                margin: 0;
                vertical-align: middle;
            }
            span.bill-control {
                display: inline-block;
                border-bottom: 1px dashed #908d72;
                width: 200px;
                margin: 0 10px;
                font-weight: 400;
            }
            span.digit{
                margin: 0;
            }
        }
        .ved-right, .vdb-right {
            float: right;
        }
        .vdb-center {
            padding-left: 22%;
        }
        .receiver-title {
            font-size: 1.15rem;
            font-weight: 500;
            margin: 0 0 20px;
        }
    } 
    .voucher__enterDetail{
        border-top: none;
    }
    .voucher__detailBy{
        border-top: none;
        border-bottom: none;
    }
}


/** PF CIT Report Start **/

.pf_cit_report{
    .NastLogo{
        width: 100px;
        img{
            max-width: 100%;
        }
    }
    .kt-voucher__head {
        margin: 0 0 30px;
        .kt-voucher__title {
            text-align: center;
            color: #595d6e;
            .kt-invoice__descTop {
                font-weight: 500;
                font-size: 1.4rem;
                margin: 0 0 5px;
            }
            .kt-invoice__descMain {
                font-size: 1.1rem;
                margin: 0 0 20px;
            }
            .kt-invoice__descFoot {
                font-size: 1.15rem;
                font-weight: 500;
            }
        }
    }
}

/** PF CIT Report End **/

/** Dashboard Start **/

.nastLogoContent{
    .alert-text{
        span {
            display: block;
            &:first-child {
                font-size: 1.4rem;
                font-weight: 500;
                margin: 0 0 1px;
            }
            &:last-child {
                font-size: 1.02rem;
            }
        }
    } 
} 

.db-fiscalYear__detail{
    .alert .alert-icon {
        padding: 0 1.2rem 0 0;
    }
    span {
        display: inline-block;
        font-size: 1.25rem;
        margin: 15px 0;
        &:first-child {
            font-weight: 500;
        }
        &:last-child {
            padding: 0 0 0 10px;
        }
    }
}

.paySlip-table__head {
    margin: 0 0 20px;
    span {
        display: block;
        margin: 0 0 2px;
        color: #595d6e;
        span {
            display: inline-block;
            padding-right: 10px;
            font-weight: 500;
            &:last-child {
                font-weight: normal;
            }
        }
    }
}

.yearlPayroll__datTable-wrapper {
    overflow-x: scroll;
    .table {
        width: 5400px;
    }
}

.tds-approve_dataTables_wrapper{
    overflow-x: scroll;
}

.kt-media-logo {
    display: inline-block;
    width: 60px;
    img{
        width: 100%;
    }
}

.veda-svg-icon2{
    width: 30px;
    height: 30px;
    .st0 {
        fill: #DEEDF9;
    }
    .st1 {
        fill: #F3F3F1;
    }
    .st2 {
        fill: #CEE1F2;
    }
    .st3 {
        fill: #ffaf30;
    }
    .st4 {
        fill: #E59328;
    }
    .st5 {
        fill: #ABC3CE;
    }
    .st6 {
        fill: #326ede;
    }
}

/** Dashboard End **/

/** File Upload Page Start **/

.custom-file{
    &.is-invalid {
        .custom-file-label {
            border-color: #fd397a;
        }
    }
}

.previewThumbnail {
    height: 60px;
    border-radius: 5px;
    background: #ebebf3;
    margin: 0 0 10px;
    overflow: hidden;
    position: relative;
    img{
        object-fit: cover;
        width: 100%;
        height: 100%;
    }
    &::before{
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: transparent;
        transition: all .2s ease-in-out;
    }
    .copy-file-url{
        width: 30px;
        height: 30px;
        position: absolute;
        top: 50%;
        left: 50%;
        margin: -15px 0 0 -15px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 100%;
        background: #fff;
        cursor: pointer;
        font-size: 16px;
        opacity: 0;
        visibility: hidden;
        transition: all .2s ease-in-out;
        &:hover{
            background: #5d78ff;
            color: #fff;
        }
    }
    &:hover{
        &::before{
            background: rgba($color: #000000, $alpha: 0.5);
        }
        .copy-file-url{
            opacity: 1;
            visibility: visible;
        }
    }
    @media(min-width: 1399px) {
        height: 100px;   
    }
}

/** File Upload Page End **/